<!-- 关于我们 -->
<template>
    <div style="position: relative;">
        <!--顶部图片-->
        <div>
            <img class="img_all" src="../../../assets/img/bg_about.png" />
        </div>
        <!--选项卡-->
        <div class="main">
            <div class="flex center_f">
                <div v-for="(item,index) in selectTabs" :key="item.position" @click="tabClick(index)" :class="index === selectTabsCheckPosition ? 'selectTabs active':' selectTabs'">
                    <div class="flex center_f">
                        <img class="img_50" :src="item.icon" />
                    </div>
                    <div class="f_20"> {{item.name}}</div>
                </div>
            </div>
        </div>

        <div class="fr">
            <img v-show="false" class="qrcode" src="../../../assets/a.png" alt="二维码">
        </div>
        <!--路由-->
        <div class="router">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            imgHeight: '',
            selectTabsCheckPosition: 0,
            selectTabs: [{
                name: '公司简介',
                icon: require('../../../assets/img/icon_gongsijianjie.png'),
                class: 'f_50 iconfont icon-gongsijianjieicon'
            }, {
                name: '资质荣誉',
                icon: require('../../../assets/img/icon_rongyu.png'),
                class: 'f_50 iconfont icon-_rongyu'
            }, {
                name: '新闻动态',
                icon: require('../../../assets/img/icon_news.png'),
                class: 'f_50 iconfont  icon-dongtai'
            }, {
                name: '联系我们',
                icon: require('../../../assets/img/icon_call.png'),
                class: 'f_50 iconfont  icon-lianxiwomen'
            }
            ]
        }
    },
    methods: {
        tabClick (index) {
            this.selectTabsCheckPosition = index
            if (index === 0) {
                this.$router.push('/index/about/companyprofile')
            } else if (index === 1) {
                this.$router.push('/index/about/honors')
            } else if (index === 2) {
                this.$router.push('/index/about/news')
            } else if (index === 3) {
                this.$router.push('/index/about/contactus')
            }
        }
    }
}

</script>
<style lang='less' scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.selectTabs {
    text-align: center;
    color: #1e90ff;
    font-size: 30px;
    display: inline-block;
    padding: 10px 40px;
    margin: 0 20px;
    line-height: 36px;
    cursor: pointer;
    transition: 1.2s;
}
.active {
    border-radius: 20px;
    border: 1px solid #00bfff;
    color: #00bfff;
    font-size: 100px !important;
    transform: scale(1.1);
}
.selectTabs:hover {
    border-radius: 20px;
    border: 1px solid #00bfff;
    color: #00bfff;
    transform: scale(1.1);
}

.router {
    margin: 0 15vw;
    height: 100%;
    margin-top: 20px;
}

.fr {
    position: fixed;
    background: red;
    right: 10px;
    top: 50%;

    .qrcode {
        position: absolute;
        top: 54px;
        right: 10px;
        background: red;
        width: 50px;
        height: 50px;
        transform-origin: top left;
        border-radius: 10px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.4s ease-in-out;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .qrcode:hover {
        width: 150px;
        height: 150px;
    }
}
</style>
