<!-- 荣誉资质 -->
<template>
  <div class="page">
    <!--资质-->
    <div class="flex center_f flex-wrap"
         style="justify-content:space-between">
      <div class="flex_v center_f"
           v-for="item in qualification"
           :key="item.name">
        <img class="img1"
             :src="item.imgUrl" />
        <span> {{item.name}}</span>
      </div>
    </div>

    <!--软件著作权-->
    <div class="m_t_40  center_h">
      <!--分割线-->
      <div style="height: 1px;width:500;background:#9D9D9D;"></div>
      <span>软件著作权</span>
    </div>

    <div class="flex center_f flex-wrap"
         style="justify-content:space-between;margin-top:10px">
      <div class="flex_v center_f "
           v-for="item in copyright"
           :key="item.name">
        <img class="img2"
             :src="item.imgUrl" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      qualification: [
        {
          imgUrl: require('@/assets/img/company/qualifications_1.gif'),
          name: '测绘资质'
        }, {
          imgUrl: require('@/assets/img/company/qualifications_2.gif'),
          name: '科技型企业'
        }, {
          imgUrl: require('@/assets/img/company/qualifications_3.gif'),
          name: '环境污染治理'
        }
      ],
      copyright: [
        {
          imgUrl: require('@/assets/img/company/software_opyright_1.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_2.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_3.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_4.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_5.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_6.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_7.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_8.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_9.jpg')
        }, {
          imgUrl: require('@/assets/img/company/software_opyright_10.jpg')
        }
      ]
    }
  },
  methods: {}
}

</script>
<style lang='less' scoped>
.page {
  .img1 {
    width: 360px;
  }

  .img2 {
    margin: 10px 10px;
    width: 192px;
  }
}
span {
  font-size: 18px;
}
</style>
