import Vue from "vue";
import App from "./App.vue";
import router from "./router";
Vue.config.productionTip = false;

// 使用elemet-ui:通过插件方式
import "@/plugins/element.js";

// 导入全局样式表
import "@/assets/css/global.css";

import "@/assets/css/font.css";

import SlideVerify from "vue-monoplasty-slide-verify";

import "@/assets/css/transition.css";

Vue.use(SlideVerify);

// bus总线
Vue.prototype.bus = new Vue();

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
