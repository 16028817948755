<!-- 首页 -->
<template>
    <div>
        <div class="el-header">
            <div class="leftWrap">
                <img class="img_50" src="../assets/logo.png" />
                <span style="font-size:21px; font-weight: 600; letter-spacing: 1px;">云南新联环境科技有限公司</span>
            </div>

            <div class="right-l">
                <el-tabs v-model="headerActiveName" @tab-click="tabClick" active-text-color="#fff" tab-position="bottom">
                    <el-tab-pane v-for="item in headerActives" :key="item.label" @mouseover="changeMask(item.label)" class="social weixin" :label="item.label" :name="item.name">
                    </el-tab-pane>
                </el-tabs>
                <img v-show="dow_two_dimensional" class="qrcode" src="../assets/img/dow_two_dimensional.png" alt="二维码">
            </div>

            <div v-if="false" class="right-r" @click="cloud">
                <img class="img_30" src="../assets/img/icon_notebook.png" />
                <span>云平台</span>
            </div>

        </div>

        <el-container>
            <el-main>
                <router-view />
            </el-main>

        </el-container>

        <footer class="my-footer flex_v center_f">
            <span>友情链接</span>
            <a class="link" href="http://www.kmnfsw.com">昆明南方水务有限公司</a>
            <p>Copyright © 2021 新联环境科技有限公司 滇ICP备16009812号 </p>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dow_two_dimensional: false,
            headerActiveName: "f",
            headerActives: [
                {
                    label: "首页",
                    name: "a",
                },
                {
                    label: "解决方案",
                    name: "b",
                },
                {
                    label: "智慧水务",
                    name: "c",
                },
                {
                    label: "管网检测",
                    name: "d",
                },
                {
                    label: "运营维护",
                    name: "e",
                },
                {
                    label: "关于我们",
                    name: "f",
                },
                {
                    label: "下载",
                    name: "g",
                },
            ],
        };
    },
    methods: {
        cloud() {
            this.$router.push("/cloud-platform/login");
        },
        changeMask(label) {
            alert(label);
        },
        tabClick(tab, event) {
            if (tab.name === "a") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/home");
            } else if (tab.name === "b") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/programme");
            } else if (tab.name === "c") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/smartwater");
            } else if (tab.name === "d") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/pipenetwork");
            } else if (tab.name === "e") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/maintenance");
            } else if (tab.name === "f") {
                this.dow_two_dimensional = false;
                this.$router.push("/index/about/companyprofile");
            } else if (tab.name === "g") {
                // 下载
                this.headerActiveName = this.headerActiveNameLast;
                this.dow_two_dimensional = !this.dow_two_dimensional;
            }
        },
    },
};
</script>
<style lang='less' scoped>
.el-main {
    z-index: 99;
    margin-top: 80px;
    padding: 0px;
}

.el-header {
    z-index: 9999;
    position: fixed;
    top: 0px;
    width: 100%;
    color: #fff;
    background: #02248f;
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 200px;

    .leftWrap {
        flex: 1;
        white-space: nowrap;
        height: 100%;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        flex: 1;
    }

    .right-l {
        flex: 1;
        margin-right: 100px;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        display: flex;
        margin-top: 10px;
    }

    .rightWrap {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    .right-r {
        white-space: nowrap;
        height: 100%;
        line-height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .el-tabs {
        height: 100%;
        margin: 0 10px;
        margin-top: 2px;
    }

    // tabs默认是14px
    /deep/.el-tabs__item {
        font-weight: 600 !important;
        font-size: 16px !important;
        padding: 0 12px;
        display: inline-block;
        list-style: none;
        font-weight: 500;
        color: #fff;
        letter-spacing: 1px;
    }

    //边框缩进：第一个不要左边框
    // /deep/.el-tabs__item + .el-tabs__item:before {
    //   content: "";
    //   width: 1px;
    //   height: 50%;
    //   background-color: #ccc;
    //   position: absolute;
    //   left: 0;
    //   top: 30%;
    // }

    /deep/.is-bottom {
        background: #02248f;
    }

    /deep/.el-tabs__item:hover {
        color: #58ff58;
    }

    /deep/.el-tabs__item.is-active {
        color: #00ff00;
    }

    //下划线
    /deep/.el-tabs__active-bar {
        background-color: #00ff00;
    }
}

.qrcode {
    position: absolute;
    top: 54px;
    right: 0px;
    background: red;
    width: 150px;
    height: 150px;
    transform-origin: top left;
    border-radius: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/*页脚的css样式*/
.my-footer {
    margin-top: 40px;
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;

    span,
    a {
        color: rgb(216, 216, 216);
        text-decoration: none;
    }

    a:hover,
    a:focus {
        color: #5d6cf8 !important;
        transition: color 0.4s;
        -moz-transition: color 0.4s;
        /* Firefox 4 */
        -webkit-transition: color 0.4s;
        /* Safari and Chrome */
        -o-transition: color 0.8s;
        /* Opera */
    }
}
</style>