<!-- 联系我们 -->
<template>
  <div class="flex page">
    <p>
      <br>
      云南新联环境科技有限公司 <br>

      电话：0871-********<br>
      邮箱：********@***.com<br>
      传真：0871-********<br>
      地址：昆明市<br>
      邮编：650000<br>
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {}
}

</script>
<style lang='less' scoped>
</style>
