import Vue from 'vue'
import { Divider, CheckboxGroup, Cascader, Checkbox, Autocomplete, Radio, Switch, Scrollbar, Popover, InputNumber, Tooltip, Tag, RadioGroup, RadioButton, Drawer, Dialog, Pagination, Upload, Progress, Tree, DatePicker, Select, Option, TableColumn, Table, Col, Row, Card, MenuItem, MenuItemGroup, Submenu, Menu, Image, Carousel, CarouselItem, Tabs, TabPane, Container, Header, Main, Aside, Message, Button, Form, FormItem, Input, MessageBox } from 'element-ui'

// Message和MessageBox不能这样用，否则初始化项目的时候会自动弹出
// Vue.use(MessageBox)
// Vue.use(Message)

Vue.use(Divider)
Vue.use(CheckboxGroup)
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(Autocomplete)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Scrollbar)
Vue.use(Popover)
Vue.use(InputNumber)
Vue.use(Tooltip)
Vue.use(Tag)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Drawer)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Tree)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(TableColumn)
Vue.use(Table)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
// Message使用需要挂载:挂载到Vue的原型对象上，这样每个组件都能通过this.$message使用；
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox
