<template>
  <div class="page">
    <div style="padding:20px 100px">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getUserList()"
            class="input-with-select">

            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="dgVisible()">上传视频</el-button>
        </el-col>
      </el-row>
    </div>

    <div>

      <el-dialog :visible.sync="dialogVisible" width="40%" height="500" :before-close="handleClose">
        <div class="dialogtitle">上传视频</div>

        <div class="flex">
          <el-upload ref="upload" :show-file-list="false" :on-exceed="handleExceed" :on-success="handleSuccess"
            :on-change="onFilesChange" :on-progress="handleProgress" :action="uploadUrl" :data="uploadData"
            name="filename" multiple :file-list="fileList" :auto-upload="false">
            <div class="flex">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>

              <el-button style="margin-left: 10px;" size="small" type="success" @click.stop="submitUpload">上传到服务器
              </el-button>

              <el-button style="margin-left: 10px;" size="small" @click.stop="clearList" type="info">清空</el-button>

              <el-button style="margin-left: 10px;" size="small" @click.stop="tryAgain()" type="info">重试</el-button>
            </div>

          </el-upload>
          <download-excel :data="json_data" :fields="json_fields" worksheet="My Worksheet" type="xls" @click.stop=""
            name="上传问题.xls">

            <el-button style="margin-left: 10px;" size="small" type="info">导出</el-button>

          </download-excel>
        </div>
        <div class="upload-list">
          <div v-for="item in fileList" :key="item.name">
            <div class="flex center_f" style="margin-right:50px;margin-top:6px">
              <span class="el-icon-video-camera-solid"></span>
              <span style="margin-left:10px">{{item.name}}</span>
              <span style="flex:1;text-align:right;color:#f00"> {{ item.state}}</span>
            </div>
            <el-progress :percentage="item.percentage" :stroke-width='2' status="warning" color="#f00"
              v-show="item.isSuccess===1"></el-progress>
          </div>

        </div>
      </el-dialog>
    </div>

    <div style="padding:0px 100px">
      <el-table :data="tableData" border style="width: 100%">

        <el-table-column type="index">
        </el-table-column>

        <el-table-column prop="fileFullName" :show-overflow-tooltip="true" width="250" align='center' label="文件名称">
        </el-table-column>
        <el-table-column prop="downloadNumber" width="80" align='center' label="下载数">
        </el-table-column>
        <el-table-column prop="fileDescribe" width="180" align='center' :show-overflow-tooltip="true" label="文件描述">
        </el-table-column>
        <el-table-column prop="fileExpandedName" align='center' width="100" :show-overflow-tooltip="true" label="文件扩展名">
        </el-table-column>

        <el-table-column prop="fileGroup" align='center' label="分组">
        </el-table-column>
        <el-table-column prop="filePath" align='center' :show-overflow-tooltip="true" label="路径">
        </el-table-column>
        <el-table-column prop="fileSize" align='center' label="文件大小">
        </el-table-column>
        <el-table-column prop="fileSource" align='center' label="文件来源">
        </el-table-column>
        <el-table-column prop="fileType" align='center' label="文件类型">
        </el-table-column>
        <el-table-column prop="uploadPerson" align='center' label="上传人">
        </el-table-column>
        <el-table-column prop="gmtModified" align='center' :show-overflow-tooltip="true" label="上传时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" align='center' width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="info" disabled size="small">编辑</el-button>

            <el-button @click="handleClick(scope.row)" type="danger" disabled size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagesize" :page-sizes="[1,2,5,10]" :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  export default {
    data() {
      return {
        dialogVisible: false,
        // 文件上传地址
        uploadUrl: 'http://gisserver.swyth.cn:38008/gisservercenter/gis-link-video/batchUploadVideoFile2',
        // 文件列表加载
        filesInfoSelect: 'http://api.swyth.cn:38008/file-dic-message/filesInfo/select',
        uploadData: {
          access_token: '',
          uploadPerson: '43',
          nodeId: '47'
        },
        fileList: [],
        tableData: [
          // {
          //   downloadNumber: 0,
          //   fileDescribe: '管网缺陷数据管线段视频文件',
          //   fileExpandedName: 'mp4',
          //   fileFullName: 'YS0600-036&YS0600-035.mp4',
          //   fileGroup: 'group1',
          //   fileId: 7383,
          //   fileName: 'YS0600-036&YS0600-035.mp4',
          //   filePath: 'M00/00/41/CmT78mGJLmmAEuvvAGz7j-F2xDY698.mp4',
          //   fileSize: 7142287,
          //   fileSource: 'GIS服务',
          //   fileType: '视频',
          //   gmtCreate: '2021-11-08 22:04:26',
          //   gmtModified: '2021-11-08 22:04:26',
          //   nodeId: 47,
          //   uploadPerson: '40'
          // }
        ],
        queryInfo: {
          access_token: '',
          pageNum: 1,
          pageSize: 10,
          nodeId: 47
        },
        total: 0,
        // 导出为excel
        json_data: [{

        }],
        json_fields: {
          文件名: 'name',
          原因: 'state'
        }
      }
    },
    mounted() {
      this.getToken()
    },
    methods: {
      clearList() {
        this.fileList = []
      },
      // 重试
      tryAgain() {
        for (var item of this.fileList) {
          item.status = 'ready'
        }
        this.$refs.upload.submit()
      },
      submitUpload() {
        this.$refs.upload.submit()
      },
      deWeight(arr) {
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = i + 1; j < arr.length; j++) {
            if (arr[i].name === arr[j].name) {
              arr.splice(j, 1)
              j--
            }
          }
        }
        return arr
      },
      onFilesChange(file, fileList) {
        this.json_data = []
        var newArr = this.deWeight(fileList)
        var len = newArr.length
        for (var i = 0; i < len; i++) {
          const item = newArr[i]
          this.json_data.push({
            name: item.name,
            state: item.state
          })
        }
        fileList = newArr
        this.fileList = fileList
      },
      handleProgress(event, file, fileList) {
        if (file.percentage === 0) {
          file.isSuccess = 1
        }
      },
      handleSuccess(response, file, fileList) {
        if (response.datas.linkFailFileNameList.length > 0) {
          file.state = '文件名无效'
        } else if (response.datas.uploadedFileNameList.length > 0) {
          file.state = '已存在'
        } else if (response.datas.uploadFailFileNameList.length > 0) {
          file.state = '上传失败'
        } else {
          // file.state = '(上传成功)'
          // 移除该文件
          for (let i = 0; i < fileList.length; i++) {
            if (file.name === fileList[i].name) {
              fileList.splice(i, 1)
            }
          }
        }
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 50个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
      },
      dgVisible() {
        this.dialogVisible = true
      },
      handleClose(done) {
        this.dialogVisible = false
      },
      async getList() {
        const { data: res } = await this.$axios.get(this.filesInfoSelect, {
          params: this.queryInfo
        })
        this.tableData = res.datas
        this.total = res.count
      },
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.getList()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage
        this.getList()
      },
      async getToken() {
        const token = Cookies.get('token')
        this.queryInfo.access_token = token
        this.uploadData.access_token = token
        this.getList()
      }
    }
  }
</script>
<style lang='less' scoped>
  /deep/.el-upload {
    margin-left: 20px;
  }

  .upload-list {
    padding: 10px 20px;
    height: 400px;
    overflow: scroll;
  }

  /deep/.el-dialog__body {
    padding: 10px 10px;
  }

  .dialogtitle {
    padding-left: 20px;
    font-weight: 700;
    color: rgb(133, 128, 128);
    font-size: 16px;
    margin-bottom: 20px;
  }

  /deep/.el-dialog__header {
    padding: 2px 0px;
  }
</style>