<!-- 二维码扫码查看 -->
<template>
  <div class="content">
    <el-card class="titlebar">

      <span @click="changebar(1)"
            :class="isA ? 'active':''">第一污水处理厂</span>
      <span @click="changebar(2)"
            :class="isB ? 'active':''">第二污水处理厂</span>
    </el-card>

    <div v-show="isA">

      <el-card>
        <p>
          普洱市思茅区第一污水处理厂位于普洱主城区以北，思茅河以东的莲花路路口，占地面积48亩，2001年云南省发改委、云南省建设厅批准建设思茅区污水处理厂（一期）及配套管网工程，总投资5690.63万元，
          2004年建成投入运行，工艺采用SBR法中的ICEAS工艺，设计规模2万m<sup>3</sup>/d。污水排放执行《城镇污水处理厂污染物排放标准》（GB18918-2002）二级标准。污水处理厂主要服务范围为普洱市主城区，
          污水处理厂尾水排入思茅河。
        </p>
        <p> 第一污水处理厂共实施两次提标改造，第一次提标改造工程于2019年开始实施，提标改造后执行城镇污水处理厂污染物排放标准》（GB18918-2002）一级A标准。
          随着主城区污水管网建设的不断完善，污水收集率的增加后，水量大、浓度高，第一污水处理厂已满负荷运行，仍无法完全处理污水管网收集水量。水质水量双提升对思茅河莲花断面达标尤为重要，
          污水厂于2021年又进行了第二次提标改造，即“水质水量双提升”应急改造工程，改造后第一污水厂处理水量由2万m<sup>3</sup>/d扩容至 2.4万m<sup>3</sup>/d，双提升后出水主要指标达到《地表水环境质量标准》(GB 3838-2002) 准IV类水标准。
        </p>
      </el-card>

      <el-card>
        <div class="title">
          粗格栅
        </div>

        <div>1.功能</div>
        <p>拦截20mm以上悬浮物及杂质，保证后续处理设施能正常运行。</p>

        <div>2.参数 </div>
        <p>钢丝绳格栅除污机（数量：2台）：电机功率1.1KW，井深9.5m，过栅流速：0.8m/s，运行速度8m/min，珊条间隙20mm，安装角度75°。</p>
      </el-card>
      <el-card>
        <div class="title">
          提升泵房
        </div>

        <div>1.功能 </div>
        <p>将污水提升至细格栅槽，提升后续工艺所需要的污水。 </p>
        <div>2.参数 </div>
        <p> 有效池容：113m<sup>3</sup>。 </p>
        <p> 潜污泵（数量：3台，两用一备）：功率45kw，流量620m<sup>3</sup>/h，扬程16m。</p>
      </el-card>

      <el-card>
        <div class="title">
          细格栅
        </div>
        <div>1.功能 </div>
        <p> 去除原污水中的悬浮物及杂质，保障后续工艺段正常运行。</p>
        <div>2.参数 </div>
        <p> 渠宽：1.10m，渠深1.8m。 </p>
        <p> 内进流细格栅（数量：2台）：处理能力54000m³/d·台，网孔间隙3mm，安装角度90°，电机功率0.75kw，减速机功率0.75kw。 </p>
        <p> 冲洗水泵（数量：2台）：流量12m<sup>3</sup>/h，扬程91m，功率5.5kw。 </p>
        <p> 内进流细格栅压榨机（数量：1台）：电机功率2.2kw，溜槽宽度0.3m。 </p>
      </el-card>
      <el-card>
        <div class="title">
          旋流沉沙池
        </div>
        <div>1.功能 </div>
        <p>去除污水中细小的悬浮物、漂浮物，减轻后续构筑物的处理负荷。 </p>
        <div>2.参数 </div>
        <p>最大处理量：460L/S，外形尺寸：Φ3.52×4.81（m）。</p>
      </el-card>

      <el-card>
        <div class="title">
          1#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：43m，宽：16m，有效水深：4.7m，水力停留时间（HRT）：12.73h，泥龄（SRT）：15～24d。</p>
        <div>3.主要设备 </div>
        <p>潜水搅拌机（数量：8台）：功率1.5kw 2台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p> 潜水回流泵（数量：1台）：功率22kW，流量1200m³/h。</p>
        <p> 剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>
      <el-card>
        <div class="title">
          2#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：43m，宽：16m，有效水深：4.7m，水力停留时间（HRT）：12.73h，泥龄（SRT）：15～24d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：8台）：功率1.5kw 2台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p> 潜水回流泵（数量：1台）：功率22kW，流量1200m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>

      </el-card>

      <el-card>
        <div class="title">
          3#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：43m，宽：16m，有效水深：4.7m，水力停留时间（HRT）：12.73h，泥龄（SRT）：15～24d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：8台）：功率1.5kw 2台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p>潜水回流泵（数量：1台）：功率22kW，流量1200m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>

      <el-card>
        <div class="title">
          4#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p>去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：43m，宽：16m，有效水深：4.7m，水力停留时间（HRT）：12.73h，泥龄（SRT）：15～24d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：8台）：功率1.5kw 2台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p>潜水回流泵（数量：1台）：功率22kW，流量1200m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>

      <el-card>
        <div class="title">
          鼓风机房
        </div>
        <div>1.功能 </div>
        <p>使水体中增加足够的溶解氧，满足好氧生物对氧气的需求。</p>
        <div>2.参数 </div>
        <p>空气悬浮离心鼓风机TB200（数量：1台）：出风流量126m<sup>3</sup>/min，转速28000RPM，功率150KW。</p>
        <p>空气悬浮离心鼓风机TB300（数量：1台）：出风流量189m<sup>3</sup>/min，转速34000RPM，功率225KW。</p>
      </el-card>
      <el-card>
        <div class="title">
          配电房
        </div>
        <div>1.功能 </div>
        <p>电力网中的线路连接点，是用以变换电压、交换功率和汇集、分配电能的设施。</p>
        <p>它主要由主变压器、配电装置及测量、控制系统等构成，是电网的重要组成部分和电能传输的重要环节，对保证电网安全、经济运行具有举足轻重的作用。变配电所中用来承担输送和分配电能任务的电路，称为一次电路或电气主接线。</p>
      </el-card>
      <el-card>
        <div class="title">
          调节池二次提升泵房
        </div>
        <div>1.功能 </div>
        <p>将MBBR反应池出水贮存，均衡水质水量提升至后续深度处理设施。</p>
        <div>2.参数 </div>
        <p>平均流量833.3m<sup>3</sup>/h，有效水深3.7m，有效容积1665m<sup>3</sup>。</p>
        <div>3.主要设备</div>
        <p>搅拌器（数量：6台）：功率3kw。</p>
        <p>污水提升泵（数量：3台，两用一备，1台变频）：流量600m<sup>3</sup>/h，扬程7m，功率22kw。</p>
        <p>电动葫芦（数量：1套）：起重量1t，H=6m，功率1.7kw。</p>
      </el-card>

      <el-card>
        <div class="title">
          高效沉淀池
        </div>
        <div>1.功能 </div>
        <p>含机械絮凝池及高效沉淀池，利用絮凝剂的絮凝作用，沉淀来水中SS、COD、TP。</p>
        <div>2.参数 </div>
        <p>混凝池Qmax=1242m<sup>3</sup>/h，混合反应时T1=105s</p>
        <div>3.主要设备</div>
        <p>（1）混合搅拌机1台，功率7.5kw。</p>
        <p>（2）机械絮凝池单池2格，设计流量621m<sup>3</sup>/h；絮凝反应时间25.3min。</p>
        <p>（3）一级絮凝搅拌机2台，功率4kw。</p>
        <p>（4）二级絮凝搅拌机2台，功率3kw。</p>
        <p>（5）刮泥机2台，功率0.75kw。</p>
        <p>（6）剩余污泥泵2台（一用一备），功率5.5kw。</p>
        <p>（7）污泥回流泵2台（一用一备），功率5.5kw。</p>
      </el-card>
      <el-card>
        <div class="title">
          纤维转盘滤池
        </div>
        <div>1.功能 </div>
        <p> 进一步去除水中含磷悬浮物及杂质，设计进水SS≤30mg/L，出水SS≤10mg/L。</p>
        <div>2.参数 </div>
        <p>Qmax1242m<sup>3</sup>/h，单台处理能力Q≥621m<sup>3</sup>/h，最大时滤速11.8m/s，转盘直径2.5m。</p>
        <div>3.主要设备</div>
        <p>（1）旋转驱动电机2台，功率0.75kw。</p>
        <p>（2）反洗泵2台，功率2.2kw。</p>
      </el-card>
      <el-card>
        <div class="title">
          紫外线消毒渠
        </div>
        <div>1.功能 </div>
        <p> 利用紫外线波长破坏微生物机体细胞中的DNA或RNA的分子结构，造成生长性细胞死亡和再生细胞死亡，达到杀菌消毒的效果。</p>
        <div>2.参数 </div>
        <p>消毒效果：粪大肠菌群＜1000个/L；紫外线剂量＞20mj/cm2。</p>
      </el-card>

      <el-card>
        <div class="title">
          加药间
        </div>
        <div>1.功能 </div>
        <p> 提供高效沉淀池絮凝剂PAM、PAC。</p>
        <div>2.参数 </div>
        <p>PAM配置溶液浓度1～3‰，投药能力2～10kg/h。</p>
        <p>PAC溶液有效含量6～7%，投药能力0～1000L/h。</p>
        <p>PAC储罐V=20m<sup>3</sup>，加药罐2只，单只V=5m<sup>3</sup>。</p>
        <div>3.主要设备</div>
        <p>（1）PAM加药螺杆泵2台（一用一备），功率0.55kw。</p>
        <p>（2）PAC计量泵2台（一用一备），功率0.75kw。</p>
        <p>（3）PAC卸料耐酸泵2台，功率1.5kw。</p>
        <p>（4）搅拌机2台，功率1.5kw。</p>
        <p>（5）玻璃钢轴流风机4台，功率0.025kw。</p>
      </el-card>

      <el-card>
        <div class="title">
          脱泥间
        </div>
        <div>1.功能 </div>
        <p>将剩余污泥进行脱水。</p>
        <div>2.主要设备及参数 </div>
        <p> 叠螺式污泥脱水机：功率1.1kw。</p>
        <p>PAM制备系统：药箱容积1.8m<sup>3</sup>，储药容积2.8m<sup>3</sup>，功率：2.515kw。</p>
        <p>无轴螺旋输送机：电机功率2.2KW，转速1400RPM，输送机转速21r/min，输送长度6.5m。</p>
        <p>污泥螺杆泵（数量：2台）：流量8-39m<sup>3</sup>/h，转速97-400rpm，电机功率11KW。</p>
        <p>加药螺杆泵：流量0.8 -4m<sup>3</sup>/h，功率1.88KW 转速75-303rpm，电机功率1.1KW。</p>
        <p>冲洗水泵：流量12m<sup>3</sup>/h，扬程121m，功率7.5KW ，转速：2900r/min。</p>
      </el-card>
      <el-card>
        <div class="title">
          污泥储池
        </div>
        <div>1.功能 </div>
        <p>储存由生化池、高效沉淀池排出的剩余污泥，通过污泥泵送至污泥脱水机房脱水。</p>
        <div>2.参数 </div>
        <p>池深：6.1m，长：13.8m，宽：8.4m。</p>
      </el-card>
    </div>

    <div v-show="isB">
      <el-card>
        <p>
          普洱市思茅区第二污水处理厂采用SBR中的ICEAS工艺，设计规模3万m<sup>3</sup>/d，占地38亩。于2009年12月10日开工建设，批复执行《城镇污水处理厂污染物排放标准》（GB18918-2002）一级B标准。
          厂址位于普洱市思茅区莲花路 4.5 公里处，项目投资7300万元，2012年11月23日普洱市环保局通过在线监测系统环保验收，于2014年5月15日通过环境保护竣工验收。
          主要服务范围为普洱市主城区，污水处理厂尾水排入思茅河。
        </p>
        <p>
          第二污水处理厂共实施两次提标改造，第一次提标改造工程于2019年开始实施，提标改造后执行城镇污水处理厂污染物排放标准》（GB18918-2002）一级A标准。
          随着主城区污水管网建设的不断完善，污水收集率的增加后，水量大、浓度高，第二污水处理厂已满负荷运行，仍无法完全处理污水管网收集水量。水质水量双提升对思茅河莲花断面达标尤为重要，
          污水厂于2021年又进行了第二次提标改造，即“水质水量双提升”应急改造工程，改造后第二污水厂处理水量由3万m<sup>3</sup>/d扩容至3.6万m<sup>3</sup>/d，双提升后出水主要指标达到《地表水环境质量标准》
          (GB 3838-2002) 准IV类水标准。
        </p>
      </el-card>
      <el-card>
        <div class="title">
          粗格栅
        </div>

        <div>1.功能</div>
        <p>拦截20mm以上悬浮物及杂质，保证后续处理设施能正常运行。</p>

        <div>2.参数 </div>
        <p>钢丝绳格栅除污机（数量：2台）：电机功率1.1KW，井深9.5m，过栅流速：0.8m/s，运行速度8m/min，珊条间隙20mm，安装角度75°。</p>
      </el-card>
      <el-card>
        <div class="title">
          提升泵房
        </div>

        <div>1.功能 </div>
        <p>将污水提升至细格栅槽，提升后续工艺所需要的污水。 </p>
        <div>2.参数 </div>
        <p> 潜污泵（数量：3台，两用一备）：功率75kw。 </p>
      </el-card>

      <el-card>
        <div class="title">
          细格栅
        </div>
        <div>1.功能 </div>
        <p> 去除原污水中的悬浮物及杂质，保障后续工艺段正常运行。</p>
        <div>2.参数 </div>
        <p> 渠宽：1.40m。 </p>
        <p> 内进流细格栅（数量：2台）：处理能力41000m³/d·台，网孔间隙3mm，安装角度90°，电机功率0.75kw，减速机功率0.75kw。 </p>
        <p> 冲洗水泵（数量：2台）：流量12m<sup>3</sup>/h，扬程91m，功率5.5kw。 </p>
        <p> 内进流细格栅压榨机（数量：1台）：电机功率2.2kw，溜槽宽度0.3m。 </p>
      </el-card>
      <el-card>
        <div class="title">
          旋流沉沙池
        </div>
        <div>1.功能 </div>
        <p>去除污水中细小的悬浮物、漂浮物，减轻后续构筑物的处理负荷。 </p>
        <div>2.参数 </div>
        <p>池深2.65m，有效池深1.9m。</p>
        <p>搅拌机（数量：1台）：功率1.5kw。</p>
        <p>螺旋式砂水分离器（数量：1台）：功率0.37kw。</p>
        <p>罗茨鼓风机（数量：1台）：功率4kw。</p>
      </el-card>

      <el-card>
        <div class="title">
          1#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：50m，宽：19.7m，有效水深：4.5m，水力停留时间（HRT）：11.75h，泥龄（SRT）：12～21d。</p>
        <div>3.主要设备 </div>
        <p>潜水搅拌机（数量：9台）：功率3.0kw 3台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p>潜水回流泵（数量：1台）：功率30kW，流量1400m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>
      <el-card>
        <div class="title">
          2#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：50m，宽：19.7m，有效水深：4.5m，水力停留时间（HRT）：11.75h，泥龄（SRT）：12～21d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：9台）：功率3.0kw 3台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p>潜水回流泵（数量：1台）：功率30kW，流量1400m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>

      </el-card>

      <el-card>
        <div class="title">
          3#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p> 去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：50m，宽：19.7m，有效水深：4.5m，水力停留时间（HRT）：11.75h，泥龄（SRT）：12～21d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：9台）：功率3.0kw 3台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p> 潜水回流泵（数量：1台）：功率30kW，流量1400m³/h。</p>
        <p> 剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>

      <el-card>
        <div class="title">
          4#ICEAS+MBBR反应池
        </div>
        <div>1.功能 </div>
        <p>去除污水中的有机污染物，硝化、脱氮、除磷。</p>
        <div>2.参数 </div>
        <p>池深：5m，长：50m，宽：19.7m，有效水深：4.5m，水力停留时间（HRT）：11.75h，泥龄（SRT）：12～21d。</p>
        <div>3.主要设备</div>
        <p>潜水搅拌机（数量：9台）：功率3.0kw 3台，功率5.5kw 4台，功率7.5kw 2台。</p>
        <p>潜水回流泵（数量：1台）：功率30kW，流量1400m³/h。</p>
        <p>剩余污泥泵（数量：1台）：扬程7.5m，流量50m³/h。</p>
      </el-card>

      <el-card>
        <div class="title">
          鼓风机房
        </div>
        <div>1.功能 </div>
        <p>使水体中增加足够的溶解氧，满足好氧生物对氧气的需求。</p>
        <div>2.参数 </div>
        <p>空气悬浮离心鼓风机TB200（数量：1台）：出风流量126m<sup>3</sup>/min，转速28000RPM，功率150KW。</p>
        <p>空气悬浮离心鼓风机TB300（数量：1台）：出风流量189m<sup>3</sup>/min，转速34000RPM，功率225KW。</p>
      </el-card>
      <el-card>
        <div class="title">
          配电房
        </div>
        <div>1.功能 </div>
        <p>电力网中的线路连接点，是用以变换电压、交换功率和汇集、分配电能的设施。</p>
        <p>它主要由主变压器、配电装置及测量、控制系统等构成，是电网的重要组成部分和电能传输的重要环节，对保证电网安全、经济运行具有举足轻重的作用。变配电所中用来承担输送和分配电能任务的电路，称为一次电路或电气主接线。</p>
      </el-card>
      <el-card>
        <div class="title">
          调节池二次提升泵房
        </div>
        <div>1.功能 </div>
        <p>将MBBR反应池出水贮存，均衡水质水量提升至后续深度处理设施。</p>
        <div>2.参数 </div>
        <p>流量1250m<sup>3</sup>/h，停留时间20min，有效水深2.5m，有效容积680m<sup>3</sup>。</p>
        <div>3.主要设备</div>
        <p>（1）潜水排污泵（数量：3台，两用一备）：流量720m<sup>3</sup>/h，扬程10m，功率37kw。</p>
        <p> （2）排空泵（数量：2台，一用一冷备）：流量50m<sup>3</sup>/h，扬程10m，功率4kw。</p>
        <p> （3）电动葫芦（数量：1台）：起重量1.5t，起升高度7.5m，功率3kw+0.4kw。</p>
      </el-card>

      <el-card>
        <div class="title">
          高效沉淀池
        </div>
        <div>1.功能 </div>
        <p>为了进一步去除水中的污染物质，在二级生化出水后加入化学絮凝剂，使污水中细小的悬浮物颗粒及胶体相互碰撞，从而形成较大的絮体颗粒，并从水中沉淀分离的过程。</p>
        <div>2.参数 </div>
        <p>流量1250m<sup>3</sup>/h，表面负荷6.5mm<sup>3</sup>/m<sup>2</sup>·h，污泥回流比5%。</p>
        <div>3.主要设备</div>
        <p>（1）污泥回流螺杆泵4台（两用两备），流量30m<sup>3</sup>/h，压力0.2Mpa，功率11kw。</p>
        <p> （2）中心传动刮泥机2台，直径11m，功率0.75kw，W=5t。</p>
        <p> （3）絮凝池搅拌器2台，桨叶直径1.5m，功率2.3kw，W=1t。</p>
        <p>（4）混合池搅拌器1台，桨叶直径1.4m，功率15kw，W=1.2t。</p>
      </el-card>
      <el-card>
        <div class="title">
          V型滤池
        </div>
        <div>1.功能 </div>
        <p> 采用单层均粒石英砂滤料截留水中悬浮杂质，进一步去除水中悬浮固体及粘附的细菌。</p>
        <div>2.参数 </div>
        <p>流量30000m<sup>3</sup>/d，分四格，每格流量7500m<sup>3</sup>/d，滤速5m/h，强制流速6.7m/h，滤料层厚度1.3m，过滤周期24h，滤池工作时间23.67h，总过滤面积253.5m2，每座单池格数：4格（每格设有2组滤床），单格滤池面积31.7m2，冲洗时间共计0.33h。</p>
        <div>3.主要设备</div>
        <p>（1）电动排水闸板4套，500*700。</p>
        <p>（2）电动排水闸板4套，400*400。</p>
      </el-card>
      <el-card>
        <div class="title">
          紫外线消毒渠
        </div>
        <div>1.功能 </div>
        <p> 利用紫外线波长破坏微生物机体细胞中的DNA或RNA的分子结构，造成生长性细胞死亡和再生细胞死亡，达到杀菌消毒的效果。</p>
        <div>2.参数 </div>
        <p>消毒效果：粪大肠菌群＜1000个/L；紫外线剂量＞20mj/cm2。</p>
      </el-card>

      <el-card>
        <div class="title">
          反冲洗泵房
        </div>
        <div>1.功能 </div>
        <p> 当V型滤池被滤水中的细小颗粒堵塞时，通过风机及反冲洗水泵协调运行，将堵塞颗粒反冲洗出去，使得V型滤池能持续运行。</p>
        <div>2.参数 </div>
        <p>第一步气冲洗强度：17L/（s.m2），气冲洗时间：4min。</p>
        <p>第二步气-水冲洗强度：气强度：17L/（s.m2），水强度：3L/（s.m2），气-水同时冲洗时间：4min。</p>
        <p>第三步水冲洗强度：6L/（s.m2），水冲洗时间：4min。</p>
        <p>反冲洗过程表面扫洗，水表扫强度：3L/（s.m2）。</p>
        <p>反冲洗周期：正常过滤时滤池反冲洗周期24～36h。</p>
        <div>3.主要设备 </div>
        <p>（1）反冲洗水泵3台（两用一备，变频控制），流量720m<sup>3</sup>/h，扬程12.5m，功率37kw。</p>
        <p>（2）L系列罗茨风机3套（两用一备），流量35.4m<sup>3</sup>/min，压强0.058Mpa，功率55kw。</p>
        <p>（3）电动葫芦1台，起重量2t，起重高度9m，功率2.5kw。</p>
        <p>（4）PLC控制站1套。</p>
      </el-card>

      <el-card>
        <div class="title">
          加药间
        </div>
        <div>1.功能 </div>
        <p> 提供高效沉淀池絮凝剂PAM、PAC。</p>
        <div>2.参数 </div>
        <p>混凝剂最大投加量：15mg/L，药剂投加量：450kg/d。混凝剂最大投加量：1mg/L，药剂投加量：30kg/d。</p>
        <div>3.主要设备</div>
        <p>（1）药剂调配装置1台，体积3m<sup>3</sup>。
        <p>（2）隔膜计量泵2台（一用一备），流量150L/h，扬程20m，功率0.7kw。</p>
        <p>（3）隔膜计量泵2台（一用一备），流量1700L/h；扬程20m，功率1.5kw。</p>
        <p>（4）耐腐蚀离心泵2台，流量15m<sup>3</sup>/h；扬程20m，功率3kw。</p>
        <p>（6）搅拌机2台，功率0.75kw。</p>
      </el-card>

      <el-card>
        <div class="title">
          脱泥间
        </div>
        <div>1.功能 </div>
        <p>将剩余污泥进行脱水。</p>
        <div>2.主要设备及参数 </div>
        <p>带式污泥浓缩脱水一体机：滤带宽度1.5m，处理能力30-40m³/h，功率3.7kw，电机功率2.2kw。搅拌器功率0.75kw，转速1500r/min。</p>
        <p>空气压缩机（数量：2台）：（1）电机电压380V，电流64A，频率50Hz；（2）容积流量0.28m³/min，电机功率2.2kw，工作压力0.8MPa。</p>
        <p> 倾斜式皮带输送机：输送长度13m，功率4kw，输出速度≤0.3m/s，电机功率2.2kw，减速机功率2.2kw。</p>
        <p> 转筒浓缩脱水机：处理能力30-40m³/h，清洗水量16m³/h，功率3.7kw。</p>
        <p> 一体化净水设备：功率13kw，频率50Hz，电机转速1330r/min，压力0.5PMa。</p>
        <p> PAM一体化加药装置：频率50Hz，功率3.9kw搅拌器（3台），进料电机功率0.18kw，转速1330r/min，搅拌器电机转速1430r/min功率15kw。</p>
        <p> 絮凝剂投加装置：制备能力2000L/h，功率2.5kw，加药泵电机功率0.55kw。</p>
        <p> 污泥螺杆泵（数量：4台）：功率11kw，流量35m³/h，减速机转速329r/min，电机功率11kw， 电流22.6A，转速1460r/min。</p>
      </el-card>
      <el-card>
        <div class="title">
          污泥储池
        </div>
        <div>1.功能 </div>
        <p>每格污泥池平面尺寸8.0m*8.0m*5.0m，有效水深4.5m，有效容积288m<sup>3</sup>。</p>
        <div>2.参数 </div>
        <p>池深：6.1m，长：13.8m，宽：8.4m。</p>
        <div> 3.主要设备</div>
        <p>潜污泵2台，功率2.2kw。</p>
      </el-card>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      isA: true,
      isB: false
    }
  },
  mounted () {
    this.aa()
  },
  methods: {
    changebar (bar) {
      if (bar === 1) {
        this.isA = true
        this.isB = false
      } else {
        this.isA = false
        this.isB = true
      }
    },
    aa () {
      // var _width = parseInt(window.screen.width)
      // var scale = _width / 640
      // var ua = navigator.userAgent.toLowerCase()
      // var result = /android (\d+\.\d+)/.exec(ua)
      // if (result) {
      //   var version = parseFloat(result[1])
      //   if (version > 2.3) {
      //     document.write('<meta name="viewport" content="width=640, minimum-scale = ' + scale + ', maximum-scale = ' + scale + ', target-densitydpi=device-dpi">')
      //   } else {
      //     document.write('<meta name="viewport" content="width=640, target-densitydpi=device-dpi">')
      //   }
      // } else {
      //   document.write('<meta name="viewport" content="width=640, user-scalable=no, target-densitydpi=device-dpi">')
      // }
    }
  }
}

</script>
<style lang='less' scoped>
.content {
  font-size: 18px;
  padding: 4px;
}

.title {
  text-align: center;
}
.titlebar {
  padding: 10px 0px;
  text-align: center;
  span {
    font-size: 18px;
    border-radius: 2px;
    padding: 4px 16px;
    margin-left: 10px;
    color: rgb(85, 84, 84);
    background: #d3d4da;
  }

  .active {
    background: #409eff;
    color: #fff;
  }
}

/deep/.el-card__body {
  padding: 10px;
}

p {
  line-height: 1.95em;
}
</style>
