<!-- 新闻动态  -->
<template>
  <div>
    <div style="border-bottom:1px solid #000">
      新闻动态/NES
    </div>

    <div class="flex">
      <!--侧边栏目-->
      <div class="flex_v w_200  m_t_20">
        <div class="newstab"
             v-for="(item,index) in leftTabs"
             :class="index===tabPosotion ? 'active':'tab'"
             @click="tabClick(index)"
             :key='item'>
          <span>{{item}}</span>
        </div>
      </div>

      <div class="m_b_200">
        <div class="news flex m_t_20"
             v-for="(item,index) in news"
             :key="index">
          <div class="img">

          </div>
          <div style="flex:1">
            <span>{{item.title}}</span>
            <span class="m_t_10">{{item.content}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabPosotion: 0,
      leftTabs: ['公司新闻', '行业动态', '政策法规'],
      news: [
        {
          imgUrl: '',
          title: 'title',
          content: 'Yunnan Xinlian Environment a registered address of room 402-408'
        }, {
          imgUrl: '',
          title: 'title',
          content: 'Yunnan Xinlian Environment a ran Xinlian Environment a registered addregistered address of room 402-408'
        }, {
          imgUrl: '',
          title: 'title',
          content: 'Yunnan Xinlian Environment a registered address of room 402-408'
        }, {
          imgUrl: '',
          title: 'title',
          content: 'Yunnan Xinlian Environment a registered addran Xinlian Environment a registered addran Xinlian Environment a registered address of room 402-408'
        }
      ]
    }
  },
  methods: {
    tabClick (index) {
      this.tabPosotion = index
    }
  }
}

</script>
<style lang='less' scoped>
.tab {
  color: #000 !important; //覆盖，不然第一次设置了color，切换不执行
  background: #fff;
}
.active {
  color: #fff;
  background: #6699ff;
}

.news {
  .img {
    display: inline-block;
    height: 100px;
    width: 180px;
    background: rgb(196, 194, 194);
  }
}

.newstab {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  display: inline-block;
  padding: 10px 10px;
  color: #fff;
}

.newstab:last-child {
  border-bottom: 1px solid #000;
}
</style>
