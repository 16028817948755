<!--  -->
<template>
  <div class="wrap">
    <div class="title">{{data.type}}</div>
    <div> 编号：{{data.number}}</div>
    <div v-if=" data.des.length>0">
      <span>监测：</span>
      <span v-for="item in data.des"
            :key="item">
        <span> {{item}}</span>
      </span>
    </div>
    <div class="flex"
         style="flex-direction:row-reverse">
      <span class="info"
            @click="clickHandler()">详情>></span>
    </div>

  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {

    }
  },
  mounted () {

  },

  methods: {
    clickHandler () {
      this.bus.$emit('mykey', this.data)
    }
  }
}

</script>
<style lang='less' scoped>
.wrap {
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  padding: 2px 0;
}

.title {
  font-weight: 700;
}

.info {
  color: #0b9ff5;
  cursor: pointer;
}
</style>
