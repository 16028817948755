<!-- 首页-->
<template>
  <div class="content">

    <div class="block">
      <el-carousel :height="imgHeight+'px'">
        <el-carousel-item :height="imgHeight+'px'">
          <img src="../../../assets/img/banner/bg_1.gif" />
        </el-carousel-item>
        <el-carousel-item :height="imgHeight+'px'">
          <img src="../../../assets/img/banner/bg_2.gif" />
        </el-carousel-item>

      </el-carousel>
    </div>

    <div style="margin:0 15vw">
      <p>
        There are moments in life when you miss someone so much that you just want to pick them from your dreams and hug them for real! Dream what you want to dream;go where you want to go;be what you want to be,because you have only one life and one chance to do all the things you want to do.

      </p>
      <p>

      </p>
      <p>
        May you have enough happiness to make you sweet,enough trials to make you strong,enough sorrow to keep you human,enough hope to make you happy? Always put yourself in others’shoes.If you feel that it hurts you,it probably hurts the other person, too.
      </p>
      <p>
        The happiest of people don’t necessarily have the best of everything;they just make the most of everything that comes along their way.Happiness lies for those who cry,those who hurt, those who have searched,and those who have tried,for only they can appreciate the importance of people
      </p>
      <p>
        who have touched their lives.Love begins with a smile,grows with a kiss and ends with a tear.The brightest future will always be based on a forgotten past, you can’t go on well in lifeuntil you let go of your past failures and heartaches.
      </p>
      <p>
        When you were born,you were crying and everyone around you was smiling.Live your life so that when you die,you're the one who is smiling and everyone around you is crying.
      </p>

      <p>
        Please send this message to those people who mean something to you,to those who have touched your life in one way or another,to those who make you smile when you really need it,to those that make you see the brighter side of things when you are really down,to those who you want to let them know that you appreciate their friendship.And if you don’t, don’t worry,nothing bad will happen to you,you will just miss out on the opportunity to brighten someone’s day with this message.
      </p>

      <p>
        We knew each other on the forum of New Oriental School. That time, we both had been hurt, drifting in
        a lonely city for a long time, yearning to fly, and craving for true and forever love.
        studied GMAT by myself in a rented small flat, she was working hard to go abroad as a
        post-doctor. Studying for the test, writing her emails, and having long talks over the phone
        late at night became a habit as well as the happiest moments of my otherwise boring and lonely
        life. Before long, I found myself attached to the sweet voice and the gentleness of the girl I had never met.
        One night, before I hung up the phone, a sadness and romance surged up in me. And I said to her: way
        of dealing with the bland school life. And I also liked to yell some tunes in the corridor or on my
        way back to my dorm at night. Some people labeled my style “street singer” who’s from the suburb of Hong Kong.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgHeight: ''
    }
  },
  mounted () {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener('resize', () => {
      this.imgLoad()
    })
  },
  created () {
    this.imgLoad()
  },
  methods: {
    imgLoad () {
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度
        this.imgHeight = document.body.clientWidth * 3 / 10
      })
    }
  }
}

</script>
<style lang='less' scoped>
.content {
  margin: 0px;
}
.el-carousel {
  margin: 0;
  padding: 0;
}
.el-carousel__item {
  color: #fff;
  font-size: 30px;
  opacity: 0.75;
  text-align: center;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #b0b0b1;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

p {
  font-size: 18px;
  margin: 10px;
  text-indent: 2em;
}

img {
  width: 100%;
}
</style>
