<!-- 公司简介 -->
<template>
  <div class="flex  page">

    <div class="video">视频介绍</div>
    <div class="des">
      <p>
        云南新联环境科技有限公司于2016年9月成立,注册资本为: 2000万元人民币，注册地为:云南
        省昆明经开区春漫大道80号云南海归创业园1幢4楼402- 408室,公司经营范围为:城市大数据与智能设
        施建设、城市基础设施综合监测检测、城市基础设施信息化管理系统开发建设与运维服务；三维技术、
        3S技术、建筑信息模型(BIM)集成技术及技术的开发，转让与咨询服务；城市规划、勘察设计、工程
        检测与安全监测、项目管理咨询服务；市政公用工程、水利水电工程、环保工程、河湖整治工程的项目
        投建、运营、管理及技术的开发、转让咨询服务；机电工程、水利水电机电安装工程、防水防腐保温工
        程；建筑劳务分包；劳务派遣；经济信息咨询；国内贸易、物资供销.
        公司目前已有市政公用工程施工总承包贰级、环保工程专业承包贰级、防水防腐工程专业承包贰级、云
        南省环境保护行业污染治理(废水治理、生态治理及恢复)乙级资质，测绘乙级资质，劳务派遣经营许
        可证，通过质量管理体系认证、环境管理体系认证、职业健康安全管理体系认证、施工劳务不分等级资
        质证书.
      </p>
      <p>
        公司成立以来高度重视科技创新及应用，在国家智慧城市和数字经济大力发展的时代背景下。成
        了”研发部”开展"互联网+智慧水务”的研发工作，结给公司的运营管理经验,以及在测绘、城市
        管网检测及普查业务的经验优势,将互联网+、物联网、云计算、大数据、地理信息等技术融合运用于
        水务行业.由水务运营、测绘、IT技术组成的专业团队，通过自主研发建立了公司的“智慧水务”平
        台，通过平台能面向城市管网、城市污水处理、河湖治理、城市内涝防控提供信息化解决方案，结合公
        司“厂网河防”一体化的治理理念，为城市水环境治理和环境保护提供领先的技术和方案支持.
      </p>

    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {}
}

</script>
<style lang='less' scoped>
.video {
  display: inline-block;
  width: 295px;
  margin-top: 12px;
  border: 1px solid #505050;
  border-radius: 2px;
  padding: 10px;
  height: 167px;
  background: rgb(230, 230, 230);
}
.des {
  margin-left: 14px;
  display: inline-block;
  flex: 1;
}
</style>
