import Vue from "vue";
import VueRouter from "vue-router";

// 新联环境主页----------------------------------------------------------------------------------
// 主页
import Index from "@/views/Index.vue";
// 首页
import Home from "@/views/offical/home/home.vue";
// 解决方案
import Programme from "@/views/offical/home/programme.vue";
// 智慧水务
import SmartWater from "@/views/offical/home/smart-water.vue";
// 管网检测
import PipeNetwork from "@/views/offical/home/pipe-network.vue";
// 运维维护
import Maintenance from "@/views/offical/home/maintenance.vue";
// 关于
import About from "@/views/offical/home/about.vue";

import CompanyProfile from "@/views/offical/home/about/company-profile.vue";
import Honors from "@/views/offical/home/about/honors.vue";
import ContactUs from "@/views/offical/home/about/contact-us.vue";
import News from "@/views/offical/home/about/news.vue";

// 临时使用的页面---------------------------------------------------------------------------
import othera from "@/views/offical/other/a.vue";
import otherupload from "@/views/offical/other/upload.vue";
import tdt from "@/views/offical/tianditu/show.vue";

Vue.use(VueRouter);

// 不需要用户权限的路由
const routes = [
    // 新联官网
    {
        path: "/index",
        redirect: "/index/about",
        meta: {
            // 页面标题title
            title: "首页",
        },
        component: Index,
        children: [
            { path: "/index/home", component: Home },
            { path: "/index/programme", component: Programme },
            { path: "/index/smartwater", component: SmartWater },
            { path: "/index/pipenetwork", component: PipeNetwork },
            { path: "/index/maintenance", component: Maintenance },
            {
                path: "/index/about",
                component: About,
                redirect: "/index/about/companyprofile",
                children: [
                    {
                        path: "/index/about/companyprofile",
                        component: CompanyProfile,
                    },
                    { path: "/index/about/honors", component: Honors },
                    { path: "/index/about/news", component: News },
                    { path: "/index/about/contactus", component: ContactUs },
                ],
            },
        ],
    },
    // 不暴露的页面
    {
        path: "/index2",
        component: othera,
    },
    {
        path: "/monitoringlocation",
        component: tdt,
    },
    {
        path: "/otherupload",
        component: otherupload,
    },
    {
        path: "/*",
        redirect: "/index/about/companyprofile",
    },
];

const router = new VueRouter({
    // mode: 'history',
    mode: "hash",
    // base: process.env.BASE_URL,
    routes: routes,
});

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }

//   if (to.path.search('/cloud-platform') !== -1) { // 只针对云平台
//     const token = Cookies.get('token')
//     console.log()
//     if (to.path === '/cloud-platform/login') { // 跳往登录界面的路由
//       // 如果是访问登录界面，
//       if (token) { // 如果用户会话信息存在，代表已登录过,不需要再停留在登录界面，跳转到主页
//         next({
//           path: '/cloud-platform'
//         })
//       } else { // 跳到登录界面
//         next()
//       }
//     } else { // 不是跳往登录界面的路由
//       if (!token) { //  未登录，则跳转到登录界面
//         // next()
//         next('/cloud-platform/login')
//       } else { // 不是登录界面，并且已经登录
//         // 加载动态菜单和路由
//         // if没有加载菜单
//         addDynamicMenuAndRoutes(to, from)
//         next()
//       }
//     }
//   } else {
//     next()
//   }
// })

// /**
//  * 加载动态菜单和路由
//  */
// function addDynamicMenuAndRoutes (to, from) {
//   if (store.state.app.menuRouteLoaded) {
//     return
//   }
//   loading.loadingShow()
//   api.user.currentMenus().then(res => {
//     loading.loadingClose()

//     // 添加动态路由
//     const cloudRouteChils = addDynamicRoutes(res.data.datas)
//     // 云平台主页，添加子路由
//     for (let i = 0; i < cloudRouteChils.length; i++) {
//       routes[1].children.push(cloudRouteChils[i])
//     }

//     // 菜单状态改为以加载
//     store.commit('menuRouteLoaded', true) // 放在router.addRoutes(routes)之前，如果放在router.addRoutes(routes)后面会出现这样的问题：第一次for循环添加了子路由，执行到router.addRoutes(routes)会刷新路由，重新走一遍router.beforeEach逻辑，此时又动态请求了一次子菜单，导致又重复添加了一遍；

//     router.addRoutes(routes)

//     // 保存菜单树
//     store.commit('setNavTree', res.data.datas)
//   }).catch(res => {
//     loading.loadingClose()
//   })
// }

// /**
//  * 递归得到全部路由
//  * @param {*} menuList 菜单列表
//  * @param {*} routes 递归创建的动态(菜单)路由
//  */
// function addDynamicRoutes (menuList, newChildren = []) {
//   var len = menuList.length
//   for (var i = 0; i < len; i++) {
//     try {
//       const item = menuList[i]
//       // @/views/cloud-platform/sys/menu.vue
//       // console.log(item.url)
//       // console.log('@/views/cloud-platform/' + item.url + '.vue')
//       // 创建路由配置
//       var route = {
//         path: item.url, // 前面不要斜杠
//          // component: resolve => require([`@/views/cloud-platform/${item.url}.vue`], resolve),
//          component: resolve => require([`@/${item.url}.vue`], resolve),
//         name: item.name,
//         meta: {
//           icon: item.css,
//           index: item.id,
//           isMenu: item.isMenu,
//           menuId: item.id
//         },
//         children: [
//           // { path: 'welcome', component: CloudWelcome }
//         ]
//       }
//       if (item.subMenus && item.subMenus.length > 0) {
//         const chils = addDynamicRoutes(item.subMenus)
//         for (let i = 0; i < chils.length; i++) {
//           newChildren.push(chils[i]) // 全部作为cloud-platform'的children
//           // route.children.push(chils[i]) //出现的问，就断child的child的path为'',也不属于cloud-platform'的子类了，需要加上中间child的path才行；
//         }
//       }
//       newChildren.push(route)
//     } catch (err) {
//       console.log(111111)
//       continue
//     }
//   }
//   newChildren = Array.from(new Set(newChildren)) // 去重
//   return newChildren
// }

export default router;
