<template>
    <div class="page">
        <div id="mapDiv"></div>

        <div class="mapSettings" @click="changeMap">{{is3D?'矢量地图':'影像地图'}}</div>

        <div class="checkSettings flex">

            <span :class="selectIndex===0 ? 'active':'tab'" @click="fliterType('排水管网',0)">排水管网</span>
            <span :class="selectIndex===1 ? 'active':'tab'" @click="fliterType('排水口',1)">排水口</span>
            <span :class="selectIndex===2 ? 'active':'tab'" @click="fliterType('泵站',2)">泵站</span>
            <span :class="selectIndex===3 ? 'active':'tab'" @click="fliterType('污水厂',3)">污水厂</span>
            <span :class="selectIndex===4 ? 'active':'tab'" @click="fliterType('重点排污户',4)">重点排污户</span>
            <span :class="selectIndex===5 ? 'active':'tab'" @click="fliterType('内涝',5)">内涝点</span>
            <span :class="selectIndex===6 ? 'active':'tab'" @click="fliterType('all',6)">全部</span>
        </div>

        <el-drawer v-if="drawerData" title="详情" :visible.sync="drawer">
            <div class="p_20 ">
                <div> 监测项目：{{drawerData.type}} </div>
                <div> 编号：{{drawerData.number}} </div>
                <div> 坐标：{{drawerData.long}} , {{drawerData.lat}}</div>
                <div> 说明：{{drawerData.info}} </div>
            </div>

        </el-drawer>
    </div>
</template>

<script>
import Vue from 'vue'
import Tip from './InfoWindowTip.vue'
var T

export default {
    data () {
        return {
            map: Object,
            drawer: false,
            is3D: true,
            lay3D: null,
            drawerData: null,
            selectIndex: 6,
            data: [{
                type: '排水管网',
                number: 'GW01',
                long: 104.41192869,
                lat: 28.63578578,
                des: ['水质', '流量'],
                info: '北大门广场处，污水进泵站。监测数据：水质、流量'
            }, {
                type: '排水管网',
                number: 'GW02',
                long: 104.4135680,
                lat: 28.63435898,
                des: ['流量'],
                info: '紫光大酒店处，污水进泵站。监测数据：监测数据：流量'
            }, {
                type: '排水管网',
                number: 'GW03',
                long: 104.41275537,
                lat: 28.62230377,
                des: ['水质', '流量'],
                info: '加油站污水流量待定，监测数据：水质、流量'
            }, {
                type: '排水管网',
                number: 'GW04',
                long: 104.41433450,
                lat: 28.61419053,
                des: ['流量'],
                info: '杨氏金福地1栋销售中心处，30处楼梯口有备选点。监测数据：流量'
            }, {
                type: '排水管网',
                number: 'GW05',
                long: 104.38073152,
                lat: 28.63985948,
                des: ['流量'],
                info: '坝尾槽迎宾大道末端，近棚户区改造项目处。监测数据：流量'
            }, {
                type: '排水口',
                number: 'PK01',
                long: 104.41154919,
                lat: 28.60715740,
                des: [],
                info: '雨水排口，北大门进城隧道口。'
            }, {
                type: '排水口',
                number: 'PK02',
                long: 104.41586839,
                lat: 28.61486984,
                des: [],
                info: '雨水排口，火车站旁加气站后，靠江边。'
            }, {
                type: '排水口',
                number: 'PK02',
                long: 104.36359497,
                lat: 28.63772436,
                des: [],
                info: '鱼塘上方楼梯口，待定'
            }, {
                type: '内涝',
                number: 'NL01',
                long: 104.39980592,
                lat: 28.63832248,
                des: [],
                info: '团结路'
            }, {
                type: '内涝',
                number: 'NL02',
                long: 104.41179397,
                lat: 28.61492145,
                des: [],
                info: '阳光路与高滩大道交汇处'
            }, {
                type: '内涝',
                number: 'NL03',
                long: 104.39980592,
                lat: 28.63832248,
                des: [],
                info: '阳光路与高滩大道交汇处'
            }, {
                type: '重点排污户',
                number: 'PWH02',
                long: 104.36865252,
                lat: 28.64087537,
                des: [],
                info: '西部大峡谷入口楼梯旁'
            }, {
                type: '泵站',
                number: 'BZ01',
                long: 104.41245317,
                lat: 28.63531755,
                des: [],
                info: '无自控系统'
            }, {
                type: '泵站',
                number: 'BZ02',
                long: 104.41342682,
                lat: 28.63537641,
                des: [],
                info: '无自控系统'
            }, {
                type: '泵站',
                number: 'BZ03',
                long: 104.41492617,
                lat: 28.61192890,
                des: [],
                info: '无自控系统'
            }, {
                type: '泵站',
                number: 'BZ04',
                long: 104.39948738,
                lat: 28.64086847,
                des: [],
                info: '待建'
            }, {
                type: '污水厂',
                number: 'WSC01',
                long: 104.41397399,
                lat: 28.62299054,
                des: [],
                info: '有自控系统，数据已接入中控室。'
            }, {
                type: '污水厂',
                number: 'WSC02',
                long: 104.39333439,
                lat: 28.63548705,
                des: [],
                info: '试运行中'
            }]
        }
    },
    async created () {

    },
    mounted () {
        T = window.T // 老校验 T is not defined,这样解决
        this.loadmap()
        this.bus.$on('mykey', (value) => {
            this.drawerData = value
            this.drawer = true
        })
    },

    methods: {
        changeMap () {
            if (!this.is3D) {
                var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
                    'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
                    '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=96edd8ddaca067d46c3d610b822925de'
                // 创建自定义图层对象
                if (this.lay3D == null) {
                    this.lay3D = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 })
                }

                // 将图层增加到地图上
                this.map.addLayer(this.lay3D)
            } else {
                if (this.lay3D) {
                    this.map.removeLayer(this.lay3D)
                }
            }
            this.is3D = !this.is3D
        },
        async loadmap () {
            var zoom = 16
            var center = new T.LngLat(104.41192869, 28.63578578)

            this.map = new T.Map('mapDiv', {
                // projection: 'EPSG:4326', //加上会导致切换3d失效
                // minZoom: 1,
                // maxZoom: 18
            })

            // 设置显示地图的中心点和级别
            this.map.centerAndZoom(center, zoom)
            this.map.enableScrollWheelZoom()
            // 创建缩放平移控件对象
            var control = new T.Control.Zoom()
            // control.setPosition({
            //   position: 'T_ANCHOR_BOTTOM_LEFT'
            // })
            // 添加缩放平移控件
            this.map.addControl(control)
            this.loadData('all')

            var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
                'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
                '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=96edd8ddaca067d46c3d610b822925de'
            // 创建自定义图层对象
            if (this.lay3D == null) {
                this.lay3D = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 })
            }

            // 将图层增加到地图上
            this.map.addLayer(this.lay3D)
        },
        loadData (type) {
            var icon = new T.Icon({
                iconUrl: './a.png',
                iconSize: new T.Point(20, 20),
                iconAnchor: new T.Point(20, 20)
            })
            for (let i = 0; i < this.data.length; i++) {
                const item = this.data[i]
                if (type !== item.type && type !== 'all') {
                    continue
                }

                const latlng = new T.LngLat(item.long, item.lat)
                const marker = new T.Marker(latlng, { icon: icon })

                const comp = new (Vue.extend(Tip))({ propsData: { data: item } }).$mount()
                // 设置信息窗口要显示的内容
                const infoWin = new T.InfoWindow(comp.$el, {
                    closeButton: false,
                    offset: new T.Point(-10, 6)
                })
                infoWin.setLngLat(latlng)
                // 设置信息窗口要显示的内容
                infoWin.setContent(comp.$el)
                // 向地图上添加信息窗口
                this.map.addOverLay(infoWin)
                this.map.addOverLay(marker)

                marker.openInfoWindow(infoWin)

                // marker.addEventListener('click', function () {
                //   const infoWin = new T.InfoWindow()
                //   infoWin.setContent(comp.$el)
                //   infoWin.setLngLat(latlng)
                //   marker.openInfoWindow(infoWin)
                // })
            }
        },
        fliterType (type, index) {
            this.selectIndex = index
            this.map.clearOverLays()
            this.loadData(type)
        }

    }
}
</script>
<style lang='less'  scoped>
.page {
    position: relative;
}

#mapDiv {
    height: 100vh;
    width: 100%;
    z-index: 0;
}

/*infowindow*/
/deep/.tdt-infowindow-content-wrapper {
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
    transform: translate(0, 14px);
}

.mapSettings {
    width: 40px;
    text-align: center;
    padding: 2px;
    font-size: 14px;
    border: solid 1px rgb(0, 89, 255);
    border-radius: 2px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    z-index: 999;
}

.checkSettings {
    padding: 1px;
    font-size: 16px;
    border-radius: 2px;
    position: absolute;
    left: 35%;
    top: 20px;
    background: #0b388a;
    z-index: 999;

    span {
        padding: 2px 10px;
    }
    span:not(:first-child) {
        margin-left: 2px;
    }
}

.tab {
    background: #fff;
}
.active {
    color: #fff;
    background: rgb(27, 130, 247);
}
</style>
