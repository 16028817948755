<template>
    <div id="app">
        <router-view />
    </div>
</template>
  
  <script>
export default {
    name: "app",
};
</script>
  
<style>
/*新增图标每次都要点击更新-*/
@import url("//at.alicdn.com/t/font_2889808_twjaf4wly7.css");
</style>
  